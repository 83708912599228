import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";

export enum ItemStatus {
  UP_TO_DATE = 1,
  INVALID,
  UPDATE_SCHEDULED,
  UPDATE_IN_PROGRESS,
  SUCCESSS,
  ERROR,
}

export type Item = {
  objectId: string;
  status: ItemStatus;
  formControl: FormGroup;
  callback: Function;
  timeout?: number;
  error?: any;
}

export class FormAutosave {
  actionDelay = 5000;
  successDelay = 1000;

  formGroups: { [key: string]: Item; } = {};

  subscribeItem(objectId: string, formControl: FormGroup, callback: Function): void {
    const item = {
      objectId, formControl, callback, status: ((formControl.value.id !== null) ? (formControl.valid) ? ItemStatus.UP_TO_DATE : ItemStatus.INVALID : ItemStatus.INVALID)
    };
    this.formGroups[objectId] = item;

    formControl.valueChanges.subscribe(event => {
      if (formControl.valid && !formControl.pristine) {
        this.scheduleItem(item);
      } else {
        this.markDirty(item);
      }
    });
  }

  private renameItem(previousObjectId: string, newObjectId: string) {
    const item = this.formGroups[previousObjectId];
    delete this.formGroups[previousObjectId];
    item.objectId = newObjectId;
    this.formGroups[newObjectId] = item;
  }

  private scheduleItem(item: Item): void {
    if (item?.timeout) {
      clearTimeout(item.timeout);
    }
    item.status = ItemStatus.UPDATE_SCHEDULED;
    item.timeout = setTimeout(() => this.markInProgress(item), this.actionDelay);
  }

  private markDirty(item: Item): void {
    item.status = ItemStatus.ERROR;
    item.status = ItemStatus.INVALID;
  }

  private markInProgress(item: Item): void {
    clearTimeout(item.timeout);
    item.status = ItemStatus.UPDATE_IN_PROGRESS;

    const actionObservable = item.callback(item.formControl.value);
    if (actionObservable instanceof Observable) {
      actionObservable.subscribe((data) => {
        if (data && data.id && data.id !== item.objectId) {
          this.renameItem(item.objectId, (data.id || item.objectId));
          item.objectId = data.id;
        }
        this.markSuccess(item)
      }, error => this.markError(item, error))
    }
  }

  private markSuccess(item: Item): void {
    item.status = ItemStatus.SUCCESSS;
    item.timeout = setTimeout((objectId: string) => {
      if (item.status === ItemStatus.SUCCESSS) {
        this.formGroups[objectId].status = ItemStatus.UP_TO_DATE;
      }
    }, this.successDelay, item.objectId);
    delete item.error;
  }

  private markError(item: Item, error: any): void {
    item.status = ItemStatus.ERROR;
    item.error = error;
  }

  getStatus(formGroupId: string, formControlIds?: string[]): ItemStatus {
    const formGroup = this.formGroups[formGroupId];
    if (formGroup && formGroup.status === ItemStatus.INVALID && formControlIds) {
      let isValid = true;
      formControlIds.forEach(id => {
        const formControl = formGroup.formControl.controls[id];
        if (formControl) {
          isValid = isValid && formControl.valid;
        }
      });
      return (isValid) ? ItemStatus.UP_TO_DATE : ItemStatus.ERROR;
    }
    return formGroup?.status;
  }
}
