import {ElementRef, Injectable} from '@angular/core';
import {ErrorPopupComponent} from "./error-popup/error-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {AbstractControl, FormArray, FormGroup, ValidationErrors} from "@angular/forms";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(public dialog: MatDialog, private router: Router) {}

  popupFormControlErrors(evt: Event, formGroup: AbstractControl): void {
    if (formGroup.valid) return;

    const target = new ElementRef(evt.currentTarget);
    const errors: any[] = [];
    Object.keys((formGroup as FormGroup).controls).forEach(key => {
      const controlErrors = formGroup.get(key)?.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    const dialogRef = this.dialog.open(ErrorPopupComponent, {
      data: { trigger: target, errors },
    });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
