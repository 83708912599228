<mat-tab-group>
  <mat-tab label="Plongée">
    <ng-template mat-tab-label>
      <i class="fa-duotone fa-mask-snorkel" style="width: 20px; height: auto;margin-right: 10px;color: #006FAA;"></i>
      <div style="font-size: large;align-items: baseline;color: #006FAA;">Plongée</div>
    </ng-template>

    <div style="display: flex; justify-content: space-evenly; width: 100%;margin-top: 10px;margin-bottom: 10px;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Aptitudes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <bulledoseur-referential-table [group]="'plongee_aptitudes'"></bulledoseur-referential-table>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>

  <mat-tab label="Activités">
    <ng-template mat-tab-label>
      <i class="fa-duotone fa-sailboat" style="width: 20px; height: auto;margin-right: 10px;color: #006FAA;"></i>
      <div style="font-size: large;align-items: baseline;color: #006FAA;">Activités</div>
    </ng-template>

    <div style="display: flex; justify-content: space-evenly; width: 100%;margin-top: 10px;margin-bottom: 10px;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Types d'activité</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <bulledoseur-referential-table [group]="'activite_type'"></bulledoseur-referential-table>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>

</mat-tab-group>

