import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ReferentialItem} from "./referential.model";

@Injectable({
  providedIn: 'root'
})
export class ReferentialService {

  private readonly REST_API_SERVER = environment.apiUrl;
  private readonly API_REFERENTIEL = 'referential/';

  constructor(private httpClient: HttpClient) { }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('DatasetService returns:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something goes wrong trying to query API/dataset.');
  }

  getRefItems(group: string): Observable<ReferentialItem[]>{
    return this.httpClient
      .get<ReferentialItem[]>(this.REST_API_SERVER + this.API_REFERENTIEL + ('?group=' + group))
      .pipe(catchError(this.handleError));
  }

  getRefItem(itemId: string): Observable<ReferentialItem>{
    return this.httpClient
      .get<ReferentialItem>(this.REST_API_SERVER + this.API_REFERENTIEL + itemId)
      .pipe(catchError(this.handleError));
  }

  createRefItem(item: ReferentialItem): Observable<ReferentialItem>{
    return this.httpClient
      .post<ReferentialItem>(this.REST_API_SERVER + this.API_REFERENTIEL, JSON.stringify(item))
      .pipe(catchError(this.handleError));
  }

  updateRefItem(item: ReferentialItem): Observable<ReferentialItem>{
    return this.httpClient
      .post<ReferentialItem>(this.REST_API_SERVER + this.API_REFERENTIEL + item.id, JSON.stringify(item))
      .pipe(catchError(this.handleError));
  }

  deleteRefItem(itemId: string): Observable<ReferentialItem>{
    return this.httpClient
      .delete<ReferentialItem>(this.REST_API_SERVER + this.API_REFERENTIEL + itemId)
      .pipe(catchError(this.handleError));
  }

}
