import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'bulledoseur-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.sass'],
  styles: []
})
export class ErrorPopupComponent {
  private readonly _matDialogRef: MatDialogRef<ErrorPopupComponent>;
  private readonly triggerElementRef: ElementRef;

  constructor(_matDialogRef: MatDialogRef<ErrorPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { trigger: ElementRef, errors: any[] }) {
    this._matDialogRef = _matDialogRef;
    this.triggerElementRef = data.trigger;
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.top + 25}px` };
    matDialogConfig.width = '500px';
    this._matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this._matDialogRef.updatePosition(matDialogConfig.position);
  }

  formatMessage(error: any): string {
    let msg = 'Le champ ' + error.control + ' ';
    switch (error.error) {
      case 'required':
        msg += 'est obligatoire.';
        break;
      case 'min':
        msg += 'doit être supérieur à ' + error.value.min + ' (actuellement ' + error.value.actual + ').';
        console.log(error);
        break;
      default:
        msg += error.error;
    }
    return msg;
  }

  cancel(): void {
    this._matDialogRef.close(null);
  }


}
