import {Component, Input, OnInit} from '@angular/core';
import {FormAutosave, ItemStatus} from "../form.autosave";
import {UtilsService} from "../../utils.service";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'bulledoseur-autosave-satus',
  templateUrl: './autosave-satus.component.html',
  styleUrls: ['./autosave-satus.component.sass']
})
export class AutosaveSatusComponent implements OnInit {
  @Input() formAutosave!: FormAutosave;
  @Input() formGroupId!: string;
  @Input() itemFormControl!: AbstractControl;
  @Input() fieldIds?: string[];

  constructor(public uiUtils: UtilsService) {
  }

  ngOnInit(): void {
  }

  getItemStatusClass(): string {
    let statusClass = 'pristine';
    const status = this.formAutosave.getStatus(this.formGroupId, this.fieldIds);
    switch (status) {
      case ItemStatus.UP_TO_DATE:
        statusClass = 'pristine';
        break
      case ItemStatus.INVALID:
        statusClass = 'invalid';
        break;
      case ItemStatus.UPDATE_SCHEDULED:
        statusClass = 'update-scheduled';
        break;
      case ItemStatus.UPDATE_IN_PROGRESS:
        statusClass = 'update-in-progress';
        break;
      case ItemStatus.SUCCESSS:
        statusClass = 'success';
        break;
      case ItemStatus.ERROR:
        statusClass = 'error';
        break;
    }
    return statusClass;
  }

  getItemStatusIcon(): string {
    let icon = 'circle';
    const status = this.formAutosave.getStatus(this.formGroupId, this.fieldIds);
    switch (status) {
      case ItemStatus.UP_TO_DATE:
        icon = 'circle';
        break;
      case ItemStatus.INVALID:
        icon = 'help_outline';
        break;
      case ItemStatus.UPDATE_SCHEDULED:
        icon = 'pending';
        break;
      case ItemStatus.UPDATE_IN_PROGRESS:
        icon = 'sync';
        break;
      case ItemStatus.SUCCESSS:
        icon = 'check_circle';
        break;
      case ItemStatus.ERROR:
        icon = 'error_outline';
        break;
    }
    return icon;
  }

  clicked($event: MouseEvent) {
    this.uiUtils.popupFormControlErrors($event, this.itemFormControl)
  }
}
