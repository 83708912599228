import {Component, Input} from '@angular/core';

@Component({
  selector: 'bulledoseur-confirm-button',
  templateUrl: './confirm-button.component.html',
  styleUrls: ['./confirm-button.component.sass']
})
export class ConfirmButtonComponent {
  @Input() callback!: (event: Event, data: any) => Promise<any>;
  @Input() callbackData: any;
  @Input() disabled: boolean;
  @Input() tooltip: string;

  buttonStyle: any;
  waitingConfirm: boolean;
  waitingCallback: boolean;

  private progress: number = 0;

  constructor() {
    this.buttonStyle = {background: 'rgba(255, 0, 0, 0)'};
    this.waitingConfirm = false;
    this.waitingCallback = false;
    this.disabled = false;
    this.tooltip = '';
  }

  click(event: Event): void {
    if (this.waitingConfirm) {
      clearInterval(this.progress);
      this.waitingConfirm = false;
      this.waitingCallback = true;
      this.buttonStyle = {background: 'rgba(0, 0, 0, 0)'};
      const promise: Promise<any> = this.callback(event, this.callbackData);
      promise.then(() => {
        this.waitingCallback = false;
        this.animateColor(true);
      }).catch((error) => {
        this.waitingCallback = false;
        this.animateColor(false);
      });
    } else {
      this.waitingConfirm = true;
      this.animateWaiting().then(() => this.waitingConfirm = false);
    }
  }

  animateWaiting(): Promise<void> {
    const instance = this;
    let count = 0;
    const steps = 30;
    return new Promise((resolve, reject) => {
      this.progress = setInterval(() => {
        instance.buttonStyle = {background: 'linear-gradient(0deg, rgb(224, 44, 54) ' + Math.round(100 * count / steps) + '%, rgb(255, 255, 255) 0%)'};
        count++;
        if (count > steps) {
          clearInterval(instance.progress);
          resolve();
          instance.animateColor(false);
        }
      }, 100);
    });
  }

  animateColor(valid: boolean): void {
    const instance = this;
    let count = 0;
    const steps = 10;
    const progress = setInterval(() => {
      const validColor = '255, 255, 255';
      const invalidColor = '255, 0, 0';
      instance.buttonStyle = {'background-color': 'rgba(' + (valid ? validColor : invalidColor) + ', ' + (1 - count / steps) + ')'};
      count++;
      if (count > steps) {
        clearInterval(progress);
      }
    }, 100);
  }
}
