<form [formGroup]="referentialForm">
  <table mat-table [dataSource]="referentialDS" formArrayName="items">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="width: 35px;padding-bottom: 2px !important;">
        <mat-icon style="width: 14px; height: 14px; font-size: medium;">sync</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element let rowIndex = index" style="padding-right: 0;padding-left: 12px;">
        <bulledoseur-autosave-satus [formGroupId]="getItemId(rowIndex)"
                               [formAutosave]="formAutosave"
                               [itemFormControl]="referentialFormArray.controls[rowIndex]">
        </bulledoseur-autosave-satus>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef style="width: 300px;"> ID</th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex">
        <input matNativeControl type="text" formControlName="id" style="margin-right: 10px;border: none;text-align: center;font-size: medium;" readonly>
      </td>
    </ng-container>
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef style="width: 40px;"> No.</th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex">
        <input matNativeControl type="text" formControlName="index" style="width: 30px;margin-right: 10px;border: none;text-align: center;font-size: medium;" readonly>
      </td>
    </ng-container>
    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef style="width: 250px;"> Group</th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex">
        <input matNativeControl type="text" formControlName="group" style="width: 250px;"
               [class]="{'invalid-field': !isFieldValid(rowIndex, 'group'), 'dirty-field': isFieldDirty(rowIndex, 'group')}">
      </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Code</th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex" style="padding-left: 10px;">
        <input matNativeControl type="text" formControlName="code" style="width: 100px;"
               [class]="{'invalid-field': !isFieldValid(rowIndex, 'code'), 'dirty-field': isFieldDirty(rowIndex, 'code')}">
      </td>
    </ng-container>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef style="width: 250px;"> Libellé</th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex" style="padding-left: 10px;">
        <input matNativeControl type="text" formControlName="label" style="width: 250px;"
               [class]="{'invalid-field': !isFieldValid(rowIndex, 'label'), 'dirty-field': isFieldDirty(rowIndex, 'label')}">
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 40px;padding-bottom: 0 !important;">
        <button mat-mini-fab aria-label="Ajouter" class="table-action-button" (click)="addRefItem()">
          <mat-icon>add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element let rowIndex = index" [formGroupName]="rowIndex" style="text-align: center;padding: 3px 10px 2px 10px;">
        <bulledoseur-confirm-button [callback]="deleteRefItem" [callbackData]="rowIndex"></bulledoseur-confirm-button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="referentialDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: referentialDisplayedColumns;" style="height: 28px;"></tr>
  </table>
</form>
