import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormAutosave} from "../../autosave/form.autosave";
import {UtilsService} from "../../utils.service";
import {ReferentialService} from "../referential.service";
import * as _ from "lodash";
import {ReferentialItem} from "../referential.model";

@Component({
  selector: 'bulledoseur-referential-table',
  templateUrl: './referential-table.component.html',
  styleUrls: ['./referential-table.component.sass']
})
export class ReferentialTableComponent implements OnInit {

  defaultItem: ReferentialItem = {
    __typename: "ReferentialItem",
    id: '',
    group: '',
    code: '',
    index: -1,
    label: '',
    createdAt: '',
    updatedAt: ''
  }
  referentialDisplayedColumns: string[] = ['status', 'num', 'code', 'label', 'actions'];

  @Input() group!: string;

  referentialDS: BehaviorSubject<AbstractControl[]>;
  referentialForm: FormGroup;
  referentialFormArray: FormArray;
  formAutosave: FormAutosave;

  deleteRefItem: any;
  private updateRefItem: any;


  constructor(public uiUtils: UtilsService, private referentialService: ReferentialService) {
    this.referentialDS = new BehaviorSubject<AbstractControl[]>([]);
    this.referentialFormArray = new FormArray([]);
    this.referentialForm = new FormGroup({
      items: this.referentialFormArray
    })
    this.formAutosave = new FormAutosave();
  }

  ngOnInit(): void {
    this.referentialForm = new FormGroup({
      items: this.referentialFormArray
    });
    this.updateRefItem = this.doUpdate.bind(this);
    this.deleteRefItem = this.removeRefItem.bind(this);
    this.referentialService.getRefItems(this.group).subscribe(
      (referential) => _.sortBy(referential, 'index').forEach((l: ReferentialItem) => {
        this.addRefItem(l);
      })
    );
  }

  addRefItem(line?: ReferentialItem): void {
    if (!line) {
      line = this.defaultItem;
    }
    const numOrdre = (line.index > -1) ? line.index : this.getNextNum();
    const row = new FormGroup({
      id: new FormControl(line.id),
      code: new FormControl(line.code, Validators.required),
      group: new FormControl(line.group || this.group, Validators.required),
      index: new FormControl({value: numOrdre, disabled: false}),
      label: new FormControl(line.label, Validators.required),
      created_at: new FormControl({value: line.createdAt, disabled: false}),
      updated_at: new FormControl({value: line.updatedAt, disabled: false}),
    });
    this.referentialFormArray.push(row);
    this.referentialDS.next(this.referentialFormArray.controls);

    const itemId = (line.id.length > 0) ? line.id : numOrdre.toString();
    this.formAutosave.subscribeItem(itemId, row, this.updateRefItem);
  }

  removeRefItem(event: Event, index: number): Promise<any> {
    let promise: Promise<any>;
    const item = this.referentialFormArray.value[index];
    if (item.id.length > 0) {
      if (item) {
        const observable = this.referentialService.deleteRefItem(item.id);
        observable.subscribe(cb => {
          this.referentialFormArray.removeAt(index)
          this.referentialDS.next(this.referentialFormArray.controls);
          // TODO handle error
        });
        promise = observable.toPromise();
      }
    } else {
      promise = new Promise<any>((resolve, reject) => {
        this.referentialFormArray.removeAt(index)
        this.referentialDS.next(this.referentialFormArray.controls);
        resolve(true);
      });
    }
    // @ts-ignore
    return promise;
  }

  doUpdate(item: ReferentialItem): Observable<ReferentialItem> {
    return new Observable(observer => {
      const observer$ = observer;
      if (item.id.length > 0) {
        this.referentialService.updateRefItem(item).subscribe(cb => {
          (this.referentialFormArray.controls[item.index - 1] as FormGroup).markAsPristine();
          observer$.next(item);
          observer$.complete();
        }, error => {
          console.warn('=>', error);
          observer$.error(error);
          // TODO handle errors
        });
      } else {
        this.referentialService.createRefItem(item).subscribe(cb => {
          let values = this.referentialFormArray.value;
          const index = _.findIndex(values, {index: item.index});
          if (index > -1) {
            values[values.indexOf(item)] = cb;
            this.referentialFormArray.patchValue(values, { emitEvent: false });
            this.referentialDS.next(this.referentialFormArray.value);
          } else {
            console.error('line not found', item, values);
          }
          (this.referentialFormArray.controls[item.index - 1] as FormGroup).markAsPristine();
          observer$.next(item);
          observer$.complete();
        }, error => {
          observer$.error(error);
          // TODO handle errors
        });
      }
    });
  }


  isFieldValid(rowIndex: number, field: string): boolean {
    const formGroup = this.referentialFormArray.controls[rowIndex] as FormGroup;
    return formGroup.controls[field].valid;
  }

  isFieldDirty(rowIndex: number, field: string): boolean {
    const formGroup = this.referentialFormArray.controls[rowIndex] as FormGroup;
    return formGroup.controls[field].dirty;
  }

  private getNextNum(): number {
    const maxNumItem2 = _.maxBy(this.referentialFormArray.controls, 'value.index');
    return ((maxNumItem2 as AbstractControl)?.value?.index || 0) + 1;
  }

  isItemValid(rowIndex: number): boolean {
    const formGroup = this.referentialFormArray.controls[rowIndex] as FormGroup;
    return formGroup.valid;
  }

  getItemId(rowIndex: number): string {
    const item = this.referentialFormArray.value[rowIndex];
    return (item.id.length > 0) ? item.id : item.index;
  }

}
