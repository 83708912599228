import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DateAdapter} from "@angular/material/core";
import {CalendarOptions, FullCalendarComponent, FullCalendarModule} from "@fullcalendar/angular";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import frLocale from "@fullcalendar/core/locales/fr";
import {DatePipe} from "@angular/common";


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  googleCalendarPlugin,
]);


@Component({
  selector: 'bulledoseur-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.sass']
})
export class PlanningComponent implements OnInit {

  // FIXME organise ton code
  // 1) les constante
  // 2) les variables de classe

  @ViewChild('calendar') calendar1!: FullCalendarComponent;
  scope = 'https://www.googleapis.com/auth/calendar'; // FIXME à mettre dans le fichier environement
  API_KEY = 'AIzaSyC5rY2anvQMkyTxdSpqds9NyGbSk0YqkMM';// FIXME à mettre dans le fichier environement

  Events = [{
    title: 'event 1', date: '2021-10-16'
  },];

  form: any;
  eventDate: any

  // calendarEl = document.getElementById('calendar') as HTMLElement;
  // calendar = new Calendar(this.calendarEl, {
  //   events:
  //     this.Events,
  //   initialView: 'timeGridWeek',
  //   locale: frLocale,
  // });

  constructor(private fb: FormBuilder, private dateAdapter: DateAdapter<any>) {
    // let calendar = new Calendar(calendarEl, {
    //   plugins: [ googleCalendarPlugin ],
    //   googleCalendarApiKey: 'AIzaSyC5rY2anvQMkyTxdSpqds9NyGbSk0YqkMM',
    //   events: {
    //     googleCalendarId: 'etienneroche24000@gmail.com'
    //   }
    // });

  }

  ngOnInit(): void {
    this.dateAdapter.setLocale('fr');
    this.form = new FormGroup({
      pickDate: new FormControl('', [Validators.required]),
      eventName: new FormControl('', [Validators.required]),
      startHour: new FormControl('', [Validators.required]),
      endHour: new FormControl('', [Validators.required]),
    });
    this.reloadCalendrier();
  }

  calendarOptions: CalendarOptions = {
    plugins: [ googleCalendarPlugin ],
    initialView: 'timeGridWeek',
    locale: frLocale,
    headerToolbar: {
      center: 'dayGridMonth,timeGridWeek,timeGridDay',
      right: 'prev,today,next'
    },
    themeSystem: 'bootstrap',
    editable: true,
    selectable: true,
    // selectMirror: true,
    // dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick: function(event){
      event.jsEvent.preventDefault();
    },
    // dateClick: this.onDateClick.bind(this),
    select: this.onZoneClick.bind(this),
    googleCalendarApiKey: 'AIzaSyC5rY2anvQMkyTxdSpqds9NyGbSk0YqkMM',
    events:
    this.Events,
    eventSources:[
      { googleCalendarId: '8fem0b9mlnr39fr448cdnjr5oo@group.calendar.google.com',
        color: '#333333'},
      { googleCalendarId: 'etienneroche24000@gmail.com'},
    ],
  };

  // someMethod(){
  //   let calendarApi = this.calendarEl.getApi();
  //   calendarApi.next();
  // }

  onDateClick(res: any) {
    // alert('Clicked on date dzdzdzdzdz: ' + res.dateStr)
    let calendarApi = this.calendar1.getApi();
    calendarApi.addEvent({
      title: 'date', date: '2021-10-14'
    })
  }

  onZoneClick(res: any){
    let calendarApi = this.calendar1.getApi();
    calendarApi.addEvent({
      title: 'zone', start: res.startStr, end: res.endStr
    })
    console.log(res.startStr);
  }

  handleDateClick(arg: any) {
    alert('date click! ' + arg.dateStr)
  }

  addEvent(){
    this.eventDate = new DatePipe('en').transform(this.form.value.pickDate, 'yyyy-MM-dd');
    console.log(this.eventDate, this.form.value.eventName, this.form.value.startHour, this.form.value.endHour);
    // console.log(this.form.pic)
    let calendarApi = this.calendar1.getApi();
    calendarApi.addEvent({
      title: this.form.value.eventName, start: this.eventDate + ' ' + this.form.value.startHour, end: this.eventDate  + ' ' + this.form.value.endHour
    })
  }

  reloadCalendrier(){
    window.dispatchEvent(new Event('resize'));
    let calendarApi = this.calendar1.getApi();
    calendarApi.render();
  }

}
