<div>
  <div style="display: flex;">
    <img src="assets/logo.png" style="width: 140px;margin: 10px 0px 10px 20px;" />


    <div style="display: flex;flex-direction: column;width: 100%;">
      <div class="header-top">
        <span class="title">Bulledoseur <div style="font-size: small;margin-left: 2px; color: rgb(224, 44, 54);">BETA</div></span>

        <div style="display: flex;margin-right: 10px;">
          <a class="view-button" routerLink="/"><mat-icon>bubble_chart</mat-icon> Ma bulle</a>
          <a class="view-button" routerLink="/planning" routerLinkActive="view-button-active"><mat-icon>event</mat-icon> Agenda</a>
          <a class="view-button" routerLink="/admin" routerLinkActive="view-button-active"><mat-icon>build</mat-icon> Admin</a>
        </div>
      </div>
      <div class="subtitle">Le Paradis des Bulles</div>
    </div>
  </div>

  <div style="margin: 5px 20px;">
    <router-outlet></router-outlet>
  </div>
</div>
