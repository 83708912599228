<form [formGroup]="form">
  <div class="row" style="width: 100%; text-align: center; padding-top: 50px;">

    <mat-form-field appearance="fill">
      <mat-label style="font-size: 18px;">Choisissez une date</mat-label>

      <input matInput [matDatepicker]="picker" formControlName="pickDate">
      <!-- <div> {{ form.value.pickDate | date:'yyyy/MM/dd'}}</div> -->

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Heure de début</mat-label>
      <input matInput placeholder="" formControlName="startHour">
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Heure de fin</mat-label>
      <input matInput placeholder="" formControlName="endHour">
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Titre de l'événement</mat-label>
      <input matInput placeholder="" formControlName="eventName">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="addEvent()" style="margin-bottom: 10px;">Ajouter un Event</button>

  </div>
</form>
<div id="test">

  <full-calendar #calendar [options]="calendarOptions" style="height: 500px; width: 800px;"></full-calendar>  <!-- [options]="calendarOptions" -->
</div>
